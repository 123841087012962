<template>
    <div class="container">
        <crud ref="crud" :options="options"></crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            categories: ['企业', '组织', '部门'],
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: false,
                addUrl: 'admin.dept.add',
                addPerm: 'admin_dept_add',
                editUrl: 'admin.dept.edit',
                editPerm: 'admin_dept_edit',
                delUrl: 'admin.dept.delete',
                delPerm: 'admin_dept_delete',
                listUrl: 'admin.dept.tree',
                formLabelWidth: '100px',
                columns: [
                    {prop: "name", label: "名称", required: true},
                    {prop: "full_name", label: "全称",},
                    {
                        prop: "parent_id",
                        label: "上级",
                        hide: true,
                        type: 'cascader',
                        dicUrl: 'admin.dept.tree',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'}
                    },
                    {
                        prop: "category", label: "类型", type: "radio", required: true,
                        value: 1,
                        dicData: [{label: '企业', value: 1}, {label: '组织', value: 2}, {label: '部门', value: 3}],
                        formatter: (row) => {
                            return this.categories[row.category - 1]
                        }
                    },
                    {prop: "sort", label: "排序", type: "number", value: 0},
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
